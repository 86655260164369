import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { ChevronDownIcon } from "lucide-react";
import React from "react";
import TimeSpan from "../models/ui/time-span";
import { useTopbarSelection } from "../store/topbar-selection-context";

const selectableTimeSpans = [
  TimeSpan.LAST_24_HOURS,
  TimeSpan.LAST_48_HOURS,
  TimeSpan.LAST_7_DAYS,
  TimeSpan.LAST_30_DAYS,
];

const TimeSpanSelector: React.FC = () => {
  const { selectedTimeSpan, setSelectedTimeSpan } = useTopbarSelection();

  return (
    <>
      {selectedTimeSpan && (
        <>
          <div className="hidden sm:flex justify-center items-center space-x-4 ml-10">
            {selectableTimeSpans.map((timeSpan) => (
              <>
                {(selectedTimeSpan != timeSpan) && (
                  <button
                    key={"button-" + timeSpan.value}
                    type="button"
                    onClick={() => {
                      setSelectedTimeSpan(timeSpan);
                    }}
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold shadow-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    {timeSpan.value}
                  </button>
                )}
                {(selectedTimeSpan == timeSpan) && (
                  <button
                    key={"button-" + timeSpan.value}
                    type="button"
                    onClick={() => {
                      setSelectedTimeSpan(timeSpan);
                    }}
                    className="rounded-md bg-blue-100 text-blue-600 border-blue-600 px-2.5 py-1.5 text-sm font-semibold  shadow-sm hover:bg-blue-300 ring-1 ring-inset ring-blue-600"
                  >
                    {timeSpan.value}
                  </button>
                )}
              </>
            ))}
          </div>

          <div className="sm:hidden">
            <Listbox
              value={selectedTimeSpan}
              onChange={(timeSpan: TimeSpan) => {
                setSelectedTimeSpan(timeSpan);
              }}
            >
              <div className="relative flex md:flex-row-reverse">
                <ListboxButton className="group inset-y-0 right-0 p-2 flex border rounded items-center relative">
                  <div className="flex min-w-0 gap-x-4">
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {selectedTimeSpan?.value}
                      </p>
                    </div>
                  </div>
                  <ChevronDownIcon className="ml-3 size-4 text-gray-400 group-data-[hover]:fill-white" />
                </ListboxButton>

                <ListboxOptions
                  className="mt-11 max-h-96 overflow-auto transform-gpu scroll-py-3 overflow-y-auto p-3 absolute z-10 flex-auto bg-white border rounded shadow-sm"
                  transition
                >
                  {selectableTimeSpans.map((timeSpan) => (
                    <ListboxOption
                      key={"option-" + timeSpan.value}
                      value={timeSpan}
                      className="group relative flex cursor-pointer select-none rounded-xl p-3 data-[focus]:bg-gray-100"
                    >
                      <div className="flex gap-x-4">
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            {timeSpan.value}
                          </p>
                        </div>
                      </div>
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </Listbox>
          </div>
        </>
      )}
    </>
  );
};

export default TimeSpanSelector;
