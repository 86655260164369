import { format } from "date-fns";
import { AdStatsGranularity } from "../api/ad-stats-request.api";
import AdStatsTimeSpan from "./ad-stats-time-span";

const hourlyFormat = (date: Date) => {
    return format(date, "HH'h'");
}

const dailyFormat = (date: Date) => {
  // TODO i18n
  return format(date, "dd.MM");
}

export default class TimeSpan {
  static readonly LAST_24_HOURS = new TimeSpan(
    "LAST_24_HOURS",
    (date: Date) => {
      return hourlyFormat(date);
    },
    (date: Date) => {
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);

      const startDate = new Date(date);
      startDate.setHours(startDate.getHours() - 24);

      return {
        startDate,
        endDate,
        granularity: AdStatsGranularity.HOURLY,
      };
    },
    (date: Date) => {
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);
      endDate.setHours(endDate.getHours() - 24);

      const startDate = new Date(date);
      startDate.setHours(23, 59, 59, 999);
      startDate.setHours(startDate.getHours() - 48);

      return {
        startDate,
        endDate,
        granularity: AdStatsGranularity.HOURLY,
      };
    }
  );

  static readonly LAST_48_HOURS = new TimeSpan(
    "LAST_48_HOURS",
    (date: Date) => {
      return hourlyFormat(date);
    },
    (date: Date) => {
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);

      const startDate = new Date(date);
      startDate.setHours(startDate.getHours() - 48);

      return {
        startDate,
        endDate,
        granularity: AdStatsGranularity.HOURLY,
      };
    },
    (date: Date) => {
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);
      endDate.setHours(endDate.getHours() - 48);

      const startDate = new Date(date);
      startDate.setHours(23, 59, 59, 999);
      startDate.setHours(startDate.getHours() - 96);

      return {
        startDate,
        endDate,
        granularity: AdStatsGranularity.HOURLY,
      };
    }
  );

  static readonly LAST_7_DAYS = new TimeSpan(
    "LAST_7_DAYS",
    (date: Date) => {
      return dailyFormat(date);
    },
    (date: Date) => {
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);

      const startDate = new Date(date);
      startDate.setDate(startDate.getDate() - 7);
      startDate.setHours(0, 0, 0, 0);

      return {
        startDate,
        endDate,
        granularity: AdStatsGranularity.DAILY,
      };
    },
    (date: Date) => {
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);
      endDate.setDate(endDate.getDate() - 7);

      const startDate = new Date(date);
      startDate.setDate(startDate.getDate() - 14);
      startDate.setHours(0, 0, 0, 0);

      return {
        startDate,
        endDate,
        granularity: AdStatsGranularity.DAILY,
      };
    }
  );

  static readonly LAST_30_DAYS = new TimeSpan(
    "LAST_30_DAYS",
    (date: Date) => {
      return dailyFormat(date);
    },
    (date: Date) => {
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);

      const startDate = new Date(date);
      startDate.setDate(startDate.getDate() - 30);
      startDate.setHours(0, 0, 0, 0);

      return {
        startDate,
        endDate,
        granularity: AdStatsGranularity.DAILY,
      };
    },
    (date: Date) => {
      const endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);
      endDate.setDate(endDate.getDate() - 30);

      const startDate = new Date(date);
      startDate.setDate(startDate.getDate() - 60);
      startDate.setHours(0, 0, 0, 0);

      return {
        startDate,
        endDate,
        granularity: AdStatsGranularity.DAILY,
      };
    }
  );

  private constructor(
    public readonly value: string,
    public readonly format: (date: Date) => string,
    private readonly getSpanForDate: (date: Date) => AdStatsTimeSpan,
    private readonly getPreviousSpanForDate: (date: Date) => AdStatsTimeSpan
  ) {}

  public getSpan(): AdStatsTimeSpan {
    return this.getSpanForDate(new Date());
  }

  public getPreviousSpan(): AdStatsTimeSpan {
    return this.getPreviousSpanForDate(new Date());
  }

  public static fromString(value: string): TimeSpan {
    switch (value) {
      case "LAST_24_HOURS":
        return TimeSpan.LAST_24_HOURS;
      case "LAST_48_HOURS":
        return TimeSpan.LAST_48_HOURS;
      case "LAST_7_DAYS":
        return TimeSpan.LAST_7_DAYS;
      case "LAST_30_DAYS":
        return TimeSpan.LAST_30_DAYS;
      default:
        throw new Error(`Unknown time span: ${value}`);
    }
  }
}

