import { BellIcon } from "@heroicons/react/24/outline";
import WebsiteSelector from "../WebsiteSelector";
import TimeSpanSelector from "../TimeSpanSelector";
import { useQuery } from "@tanstack/react-query";
import notificationService from "../../api/notification.service";

const Topbar: React.FC<{onNotificationsClicked: () => void}> = ({onNotificationsClicked}) => {
  const { data: hasUnreadNotifications } = useQuery({
    queryKey: ["notifications", "hasUnread"],
    queryFn: notificationService.hasUnread,
    staleTime: 1000 * 5, // 5 seconds
    refetchInterval: 1000 * 5, // 5 seconds
  });

  return (
    <div className="sticky top-0 z-40 bg-white flex-row border-b py-2 px-4 md:flex hidden">
      <div className="flex items-center">
        <img
          src={`${process.env.PUBLIC_URL}/logo.svg`}
          className="h-8 w-auto"
          alt="logo"
        />
      </div>
      <TimeSpanSelector />
      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 flex-row-reverse">
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <WebsiteSelector />

          {/* Separator */}
          <div
            aria-hidden="true"
            className="hidden lg:block lg:h-10 lg:w-px lg:bg-gray-200 h-full"
          />

          <button
            type="button"
            className="-m-2.5 -ml-5 p-2.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">View notifications</span>
            <div className="wrap relative">
              <BellIcon aria-hidden="true" className="h-6 w-6" onClick={onNotificationsClicked} />
              {hasUnreadNotifications &&
                hasUnreadNotifications.data.hasUnread && (
                  <div className="flex-none rounded-full p-0.5 text-rose-400 bg-rose-200 absolute top-0 right-0">
                    <div className="h-2 w-2 rounded-full bg-current"></div>
                  </div>
                )}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
