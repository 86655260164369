import "./App.css";
import PlacementsList, {
  loader as placementsListLoader,
} from "./pages/placement/list/PlacementsList";
import AdsList, { loader as adsListLoader } from "./pages/ad/AdsList";
import RootLayout from "./pages/layout/RootLayout";
import { queryClient } from "./api/common";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import WebsiteList from "./pages/WebsitesList";
import PlacementDetail, {
  loader as placementDetailLoader,
} from "./pages/placement/detail/PlacementDetail";
import AdSetup from "./pages/ad/setup/AdSetup";
import AdSetupSettingsStep from "./pages/ad/setup/AdSetupSettingsStep";
import AdSetupTargetingStep from "./pages/ad/setup/targeting/AdSetupTargetingStep";
import AdSetupPaymentStep from "./pages/ad/setup/AdSetupPaymentStep";
import Settings from "./pages/settings/Settings";
import { loader as authLoader, authSuccessLoader } from "./util/auth";
import { loader as settingsLoader } from "./pages/settings/Settings";
import NotFoundPage from "./pages/404/404";
import AdDetail, {
  loader as adDetailLoader,
} from "./pages/ad/detail/AdDetail";
import Dashboard from "./pages/dashboard/Dashboard";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    element: <RootLayout />,
    loader: authLoader,
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/placements",
        element: <PlacementsList />,
        loader: placementsListLoader,
        children: [
          { path: "/placements/page/:pageNumber", element: <PlacementsList /> },
        ],
      },
      {
        path: "/placements/:placementId",
        element: <PlacementDetail />,
        loader: placementDetailLoader,
      },
      {
        path: "/ads",
        element: <AdsList />,
        loader: adsListLoader,
        children: [{ path: "/ads/page/:pageNumber", element: <AdsList /> }],
      },
      {
        path: "/ads/:adId",
        element: <AdDetail />,
        loader: adDetailLoader,
      },
      {
        path: "/ads/setup",
        element: <AdSetup />,
        children: [
          { path: "/ads/setup", element: <AdSetupSettingsStep /> },
          {
            path: "/ads/setup/:adId/targeting",
            element: <AdSetupTargetingStep />,
          },
          { path: "/ads/setup/:adId/payment", element: <AdSetupPaymentStep /> },
        ],
      },
      { path: "/websites", element: <WebsiteList /> },
      { path: "/settings", element: <Settings />, loader: settingsLoader },
    ],
  },
  {
    path: "/auth/success",
    loader: authSuccessLoader,
  },
  { path: "*", element: <NotFoundPage /> },
]);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
