import { useQuery } from "@tanstack/react-query";
import { Suspense } from "react";
import { LoaderFunctionArgs, useParams } from "react-router-dom";
import { queryClient } from "../../../api/common";
import placementService from "../../../api/placement.service";
import LoadingIndicator from "../../../components/LoadingIndicator";
import StatsCard from "../../../components/stats/StatsCard";
import PlacementDetailAdList from "./PlacementDetailAdList";
import { useTopbarSelection } from "../../../store/topbar-selection-context";
import adStatsService from "../../../api/ad-stats.service";
import { AdStatsRequestBuilder } from "../../../models/api/ad-stats-request.api";

const PlacementDetail = () => {
  const statsTimeSpan = useTopbarSelection()?.selectedTimeSpan;

  const { placementId } = useParams();

  const { data } = useQuery({
    queryKey: ["placements", placementId],
    queryFn: () => placementService.single(Number(placementId)),
  });

  const {data: stats} = useQuery({
    queryKey: ["placements-stats", placementId, statsTimeSpan],
    queryFn: async () => {
      if (placementId && statsTimeSpan) {
        const stats = await adStatsService.get(
          new AdStatsRequestBuilder()
            .withPlacement(Number(placementId))
            .withTimeSpan(statsTimeSpan.getSpan())
            .build()
        );

        return adStatsService.sumAll(stats.data).stats[0];
      }
    },
  });

  const {data: prevStats} = useQuery({
    queryKey: ["placements-prev-stats", placementId, statsTimeSpan],
    queryFn: async () => {
      if (placementId && statsTimeSpan) {
        const stats = await adStatsService.get(
          new AdStatsRequestBuilder()
            .withPlacement(Number(placementId))
            .withTimeSpan(statsTimeSpan.getPreviousSpan())
            .build()
        );

        return adStatsService.sumAll(stats.data).stats[0];
      }
    },
  });

  const placement = data?.data;

  return (
    <>
      <main>
        <header>
          <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
            <div>
              <div className="flex items-center gap-x-3">
                <h1 className="flex gap-x-3 text-base leading-7">
                  <span className="font-semibold text-gray-900">
                    {placement?.website?.name}
                  </span>
                  <span className="text-gray-600">/</span>
                  <span className="font-semibold text-gray-900">
                    {placement?.name}
                  </span>
                  <span className="text-gray-600">({placement?.category})</span>
                </h1>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap"></p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                  {placement?.description?.text}
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">
                  {placement?.description?.tags.join(", ")}
                </p>
              </div>
            </div>
            <div className="order-first flex-none rounded-full bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30 sm:order-none">
              Production
            </div>
          </div>
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              <StatsCard index={0} title="Impressions" value={stats?.impressions || 0} prevValue={prevStats?.impressions || 0} />
              <StatsCard
                index={1}
                title="Clicks"
                value={stats?.clicks || 0} prevValue={prevStats?.clicks || 0}
                tooltip="Test"
              />
              { /* TODO */ }
              <StatsCard index={2} title="Revenue" value={300} />
              <StatsCard index={3} title="Ads" value={3} />
            </dl>
          </div>
        </header>
        <div className="px-4 pt-4 sm:px-6 sm:pt-6 lg:px-8">
          <h1 className="text-base font-semibold leading-7 text-gray-900">
            Latest ads
          </h1>
        </div>
        <Suspense fallback={<LoadingIndicator />}>
          <PlacementDetailAdList />
        </Suspense>
      </main>
    </>
  );
};

export default PlacementDetail;

export function loader({ params }: LoaderFunctionArgs) {
  return queryClient.fetchQuery({
    queryKey: ["placements", params.placementId],
    queryFn: () => placementService.single(Number(params.placementId)),
  });
}
