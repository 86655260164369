import { useTopbarSelection } from "../../store/topbar-selection-context";

import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from "recharts";

import {
  ChartBarIcon,
  CursorArrowRaysIcon,
  EyeIcon,
  NoSymbolIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import adStatsService from "../../api/ad-stats.service";
import placementService from "../../api/placement.service";
import Card from "../../components/card/Card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../components/shadcn/chart";
import StatsCard from "../../components/stats/StatsCard";
import {
  AdStatsDimension,
  AdStatsRequestBuilder,
} from "../../models/api/ad-stats-request.api";
import { useState } from "react";
import AdStats from "../../models/api/ad-stats.api";
import { useNavigate } from "react-router-dom";
import Placement from "../../models/api/placement.api";

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "Mobile",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig;

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  const [selectedPlacement, setSelectedPlacement] = useState(0);
  const { selectedWebsite: website, selectedTimeSpan: timeSpan } =
    useTopbarSelection();

  const { data: placements } = useQuery({
    queryKey: ["placements-stats", "website", website?.id],
    queryFn: async () =>
      // TODO error handling
      (await placementService.list(website?.id, 0, 200))?.data.content,
  });

  const { data: stats } = useQuery({
    queryKey: ["website-stats", website?.id, timeSpan],
    queryFn: async () => {
      if (website?.id && timeSpan) {
        const stats = await adStatsService.get(
          new AdStatsRequestBuilder()
            .withWebsite(Number(website.id))
            .withDimension(AdStatsDimension.PLACEMENT)
            .withTimeSpan(timeSpan.getSpan())
            .build()
        );
        // TODO error handling
        return stats.data;
      }
    },
    enabled: !!website?.id && !!timeSpan,
  });

  const { data: prevStats } = useQuery({
    queryKey: ["website-prev-stats", website?.id, timeSpan],
    queryFn: async () => {
      if (website?.id && timeSpan) {
        const stats = await adStatsService.get(
          new AdStatsRequestBuilder()
            .withWebsite(Number(website.id))
            .withDimension(AdStatsDimension.PLACEMENT)
            .withTimeSpan(timeSpan.getPreviousSpan())
            .build()
        );

        return stats.data;
      }
    },
    enabled: !!website?.id && !!timeSpan,
  });

  let chartData: AdStats[] = [];

  if (stats && timeSpan) {
    if (selectedPlacement) {
      chartData = adStatsService.sum(stats, [
        AdStatsDimension.DATA_TIME,
        AdStatsDimension.PLACEMENT,
      ]).stats;
      chartData = chartData.filter(
        (stat) => stat.placement?.id == selectedPlacement
      );
    } else {
      chartData = adStatsService.sum(stats, [AdStatsDimension.DATA_TIME]).stats;
    }
  }

  const getPlacementStats = (id?: number) => {
    if (id && stats && timeSpan) {
      const filtered = adStatsService
        .sum(stats, [AdStatsDimension.PLACEMENT])
        .stats.filter((stat) => stat.placement?.id == id);
      if (filtered.length > 0) {
        return filtered[0];
      }
    }
    
    return {
      clicks: 0,
      impressions: 0,
      requests: 0,
    };
  };

  const viewPlacement = (placement: Placement) =>
    navigate(`/placements/${placement.id}`);

  // TODO notifications informer
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 px-4 sm:px-6 lg:px-8">
      <Card className="md:col-span-3">
        <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
          {(() => {
            const statsSum = adStatsService.sumTotal({ stats: chartData || [] })
              .stats?.[0];
            let filteredPrevStats: AdStats[] = [];

            if (prevStats) {
              if (selectedPlacement) {
                filteredPrevStats = prevStats.stats.filter(
                  (stat) => stat.placement?.id == selectedPlacement
                );
              } else {
                filteredPrevStats = prevStats.stats;
              }
            }

            const prevStatsSum = adStatsService.sumTotal({
              stats: filteredPrevStats || [],
            }).stats?.[0];

            return (
              <>
                <StatsCard
                  index={0}
                  title="Impressions"
                  value={statsSum?.impressions || 0}
                  prevValue={prevStatsSum?.impressions || 0}
                />
                <StatsCard
                  index={1}
                  title="Clicks"
                  value={statsSum?.clicks || 0}
                  prevValue={prevStatsSum?.clicks || 0}
                  tooltip="Test"
                />
                {/* TODO */}
                <StatsCard index={2} title="Revenue" value={300} />
                <StatsCard index={3} title="Ads" value={3} />
              </>
            );
          })()}
        </dl>
      </Card>
      <Card className="md:col-span-2 pt-4">
        {stats && timeSpan && (
          <ChartContainer config={chartConfig} className="w-full">
            <LineChart accessibilityLayer data={chartData}>
              <Legend />
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="dataTime"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value) => {
                  return timeSpan.format(value);
                }}
              />
              <YAxis yAxisId="left" />
              <YAxis yAxisId="right" orientation="right" />
              <ChartTooltip
                cursor={false}
                content={
                  <ChartTooltipContent
                    labelFormatter={
                      // TODO internationalize
                      (value) => format(value, "dd.MM.yyyy HH:mm:ss")
                    }
                  />
                }
              />
              <Line
                yAxisId="left"
                dataKey="impressions"
                type="monotone"
                stroke="var(--color-desktop)"
                strokeWidth={2}
                dot={false}
              />
              <Line
                yAxisId="right"
                dataKey="clicks"
                type="monotone"
                stroke="var(--color-mobile)"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ChartContainer>
        )}
      </Card>
      <div className="md:col-span-1">
        <Card>
          <table className="min-w-full divide-y divide-gray-300 table-auto">
            <tbody className="divide-y divide-gray-200 bg-white text-left">
              {placements?.map((placement) => (
                <tr key={placement.tag}>
                  <td className="w-16 text-sm pl-4 py-4">
                    <img
                      className="invisible md:visible"
                      src={`/img/placement/${placement.category.toLowerCase()}.svg`}
                      alt={placement.category}
                    />
                  </td>
                  <td className="whitespace-nowrap w-1 px-4">
                    <p className="text-sm font-semibold leading-6 text-gray-900">
                      {placement.name}
                    </p>
                  </td>
                  <td>
                    <div className="mt-1 flex flex-col gap-x-2 text-xs leading-5 text-gray-500">
                      {(() => {
                        const placementStats = getPlacementStats(placement.id);
                        return (
                          <>
                            <p className="truncate">
                              <EyeIcon className="w-4 h-4 inline" />{" "}
                              {placementStats.impressions}
                            </p>
                            <p className="truncate">
                              <CursorArrowRaysIcon className="w-4 h-4 inline" />{" "}
                              {placementStats.clicks}
                            </p>
                          </>
                        );
                      })()}
                    </div>
                  </td>
                  <td>
                    {selectedPlacement !== placement.id && (
                      <PresentationChartLineIcon
                        onClick={() => setSelectedPlacement(placement.id || 0)}
                        className="inline w-6 h-6 cursor-pointer text-gray-600 hover:text-blue-600 flex-shrink-0"
                      />
                    )}
                    {selectedPlacement === placement.id && (
                      <NoSymbolIcon
                        onClick={() => setSelectedPlacement(0)}
                        className="inline w-6 h-6 cursor-pointer text-gray-600 hover:text-red-600 flex-shrink-0"
                      />
                    )}
                    <EyeIcon
                      onClick={() => viewPlacement(placement)}
                      className="ml-2 inline w-6 h-6 cursor-pointer text-gray-600 hover:text-blue-600 flex-shrink-0"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};

export default Dashboard;
