import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { createContext, useContext, useState } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import { cn } from "../../lib/shadcn/utils";
import { useTranslation } from "react-i18next";

interface Links {
  label: string;
  href: string;
  icon: React.JSX.Element | React.ReactNode;
}

interface SidebarContextProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  animate: boolean;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(
  undefined
);

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};

export const SidebarProvider = ({
  children,
  open: openProp,
  setOpen: setOpenProp,
  animate = true,
}: {
  children: React.ReactNode;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  animate?: boolean;
}) => {
  const [openState, setOpenState] = useState(false);

  const open = openProp !== undefined ? openProp : openState;
  const setOpen = setOpenProp !== undefined ? setOpenProp : setOpenState;

  return (
    <SidebarContext.Provider value={{ open, setOpen, animate: animate }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const Sidebar = ({
  children,
  open,
  setOpen,
  animate,
}: {
  children: React.ReactNode;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  animate?: boolean;
}) => {
  return (
    <SidebarProvider open={open} setOpen={setOpen} animate={animate}>
      {children}
    </SidebarProvider>
  );
};

export const SidebarBody = (props: React.ComponentProps<"div">) => {
  return (
    <>
      <DesktopSidebar {...props} />
      <MobileSidebar {...(props as React.ComponentProps<"div">)} />
    </>
  );
};

export const DesktopSidebar = ({
  className,
  children,
  ...props
}: React.ComponentProps<"div">) => {
  const { open, setOpen, animate } = useSidebar();
  return (
    <>
      <div
        className={cn(
          "h-full px-4 py-4 hidden  md:flex md:flex-col bg-white flex-shrink-0 transition-all duration-300 ease-in-out border-r overflow-hidden",
          className,
          open ? "w-[240px]" : "w-[60px]"
        )}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        {...props}
      >
        {children}
      </div>
    </>
  );
};

export const MobileSidebar = ({
  className,
  children,
  ...props
}: React.ComponentProps<"div">) => {
  const { open, setOpen } = useSidebar();
  return (
    <>
      <div
        className={cn(
          "h-10 px-4 py-4 flex flex-row md:hidden items-center justify-between bg-white w-full"
        )}
        {...props}
      >
        <div className="flex justify-end z-20 w-full">
          <Bars3Icon
            className="text-gray-400 h-8 w-8 hover:cursor-pointer mt-4"
            onClick={() => setOpen(open => !open)}
          />
        </div>
        {open && (
          <div
            className={cn(
              "fixed h-full w-full inset-0 bg-white p-10 z-[100] flex flex-col justify-between transform transition-transform duration-300 ease-in-out",
              open
                ? "translate-x-0 opacity-100"
                : "-translate-x-full opacity-0",
              className
            )}
          >
            <div
              className="absolute right-0 top-0 z-50 px-4 py-4"
              onClick={() => setOpen(open => !open)}
            >
              <XMarkIcon className="text-gray-400 h-8 w-8 hover:cursor-pointer" />
            </div>
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export const SidebarLink = ({
  link,
  className,
  ...props
}: {
  link: Links;
  className?: string;
  props?: NavLinkProps;
}) => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, null, "sidebar");

  const { open, animate } = useSidebar();
  return (
    <NavLink
      to={link.href}
      className={cn(
        "flex items-center justify-start gap-2 group/sidebar py-2",
        className
      )}
      {...props}
    >
      {link.icon}

      <span
        className={cn(
          "text-sm text-gray-700 group-hover/sidebar:text-blue-600 group-hover/sidebar:translate-x-1 whitespace-pre inline-block !p-0 !m-0 transition duration-150",
          animate ? (open ? "opacity-100" : "opacity-0 hidden") : "opacity-100"
        )}
      >
        {t(link.label)}
      </span>
    </NavLink>
  );
};
