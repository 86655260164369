import AdTarget from "../models/api/ad-target.api";
import Ad from "../models/api/ad.api";
import { useTranslation } from "react-i18next";

const AdStatusBadge: React.FC<{ ad: Ad, target?: AdTarget }> = ({ ad, target }) => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, null, "ad.status.badge");

  let status: string | undefined = undefined;

  const now = new Date();

  if (!target?.approved) {
    status = "unapproved";
  } else if (!target?.paid) {
    status = "unpaid";
  }

  if (status === undefined) {
    if (!ad.targets || ad.targets.length === 0) {
      status = "draft";
    } else if (ad.startDate < now && ad.endDate > now) {
      status = "active";
    } else if (ad.endDate < now) {
      status = "expired";
    } else {
      status = "scheduled";
    }
  }


  const statusClasses: Record<string, string> = {
    draft: "bg-gray-100 text-gray-600 ring-gray-500/10",
    active: "bg-green-100 text-green-700 ring-green-600/20",
    expired: "bg-red-100 text-red-700 ring-red-600/10",
    scheduled: "bg-yellow-100 text-yellow-800 ring-yellow-600/20",
    unapproved: "bg-red-100 text-red-700 ring-red-600/10",
    unpaid: "bg-red-100 text-red-700 ring-red-600/10"
  };

  const statusFill: Record<string, string> = {
    draft: "fill-gray-400",
    active: "fill-green-500",
    expired: "fill-red-500",
    scheduled: "fill-yellow-500",
    unapproved: "fill-red-500",
    unpaid: "fill-red-500"
  };

  return (
    <span
      className={`inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${statusClasses[status]}`}
    >
      <svg
        viewBox="0 0 6 6"
        aria-hidden="true"
        className={`h-1.5 w-1.5 ${statusFill[status]}`}
      >
        <circle r={3} cx={3} cy={3} />
      </svg>
      {t(status)}
    </span>
  );
};

export default AdStatusBadge;
