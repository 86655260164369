import { PhotoIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { srLatn } from "date-fns/locale";
import { FormEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import adService from "../../../api/ad.service";
import { queryClient } from "../../../api/common";
import {
  DateRange,
  DateRangePicker,
} from "../../../components/calendar/DateRangePicker";
import DateRangePresets from "../../../components/calendar/DateRangePresets";
import Tooltip from "../../../components/Tooltip";
import Ad from "../../../models/api/ad.api";

const AdSetupSettingsStep: React.FC = () => {
  const initStartDate = new Date();
  const initEndDate = new Date();
  initEndDate.setDate(initStartDate.getDate() + 7);

  const navigate = useNavigate();
  const formRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [dateRange, setDateRange] = useState<DateRange | undefined>({from: initStartDate, to: initEndDate});

  const handleDateRangeUpdate = (values: { range: DateRange | undefined }) => {
    setDateRange(values.range);
  };

  const saveAd = async(data: FormData) => {
    // TODO validation
    const ad: Ad = {
      name: data.get("ad-name")?.toString() || "",
      url: data.get("ad-url")?.toString() || "",
      startDate: dateRange?.from || new Date(),
      endDate: dateRange?.to || new Date(),
    };
  
    const resp = await adService.create(ad, file || new File([], ""));
  
    // TODO error handling
  
    return resp.data;
  }

  // TODO error handling
  const { mutate, isPending } = useMutation({
    mutationFn: saveAd,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["ads"] });
      navigate(`/ads/setup/${data.id}/targeting`);
    },
  });

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (formRef.current) {
      const formData = new FormData(formRef.current);
      mutate(formData);
    }
  }

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files ? e.target.files[0] : undefined;
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  return (
    <form method="POST" encType="multipart/form-data" ref={formRef}>
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Base settings
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              These settings will influence the behavior and look of your ad.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="col-span-full">
              <label
                htmlFor="ad-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Name
              </label>
              <div className="mt-2">
                <input
                  id="ad-name"
                  name="ad-name"
                  type="text"
                  autoComplete="ad-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="ad-url"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <div className="inline-flex gap-1 items-center">
                  URL
                  <Tooltip text="URL that will be opened when the ad is clicked" />
                </div>
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                    http://
                  </span>
                  <input
                    id="ad-url"
                    name="ad-url"
                    type="text"
                    placeholder="www.example.com"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                <div className="inline-flex gap-1 items-center">
                  Creative
                  <Tooltip text="Image that will be displayed as the ad" />
                </div>
              </label>
              <div
                className={`${
                  dragging
                    ? "border-blue-500 bg-blue-100"
                    : "border-gray-900/25"
                } mt-2 flex justify-center rounded-lg border border-dashed ${file ? "p-2" : "px-6 py-10"}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="text-center">
                  {file ? (
                    <div className="relative group">
                      <img
                        src={URL.createObjectURL(file)}
                        alt="Preview"
                        className="mx-auto w-full object-cover rounded"
                      />
                      <button
                        type="button"
                        onClick={() => setFile(undefined)}
                        className="absolute -top-3 -right-3 p-1 bg-white rounded-full hover:bg-gray-50 hover:text-blue-600"
                      >
                        <XMarkIcon
                          className="h-6 w-6 text-gray-700 group-hover:text-blue-600"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  ) : (
                    <>
                      <PhotoIcon
                        aria-hidden="true"
                        className={`${
                          dragging ? "text-blue-300" : "text-gray-300"
                        } mx-auto h-12 w-12`}
                      />
                      <div className="mt-4 flex text-sm leading-6 text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
                        >
                          <span>Upload a file</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            onChange={handleFileChange}
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-gray-900/10 pb-12 md:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Date range
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Set up the date range when you want to your ad to be active.
            </p>
            <DateRangePresets selectedRange={dateRange} onPresetClicked={(range) => handleDateRangeUpdate({range})} />
          </div>
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="col-span-full">
              <DateRangePicker
                onUpdate={handleDateRangeUpdate}
                initialRange={dateRange}
                align="start"
                locale={srLatn}
                showCompare={false}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b pb-12 md:grid-cols-3">
          <div className="grid w-full grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-3 order-last">
            <button
              type="button"
              onClick={handleSubmit}
              className="min-w-24 ml-4 inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:bg-blue-300 disabled:text-gray-100 disabled:cursor-not-allowed transition-all duration-300"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdSetupSettingsStep;
