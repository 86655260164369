import { useTranslation } from "react-i18next";

const buttonClasses =
  "rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold shadow-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50";

const selectedButtonClasses =
  "rounded-md bg-blue-100 text-blue-600 border-blue-600 px-2.5 py-1.5 text-sm font-semibold shadow-sm hover:bg-blue-300 ring-1 ring-inset ring-blue-600";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="flex space-x-2">
      <button
        onClick={() => changeLanguage("en")}
        className={
          i18n.language === "en" ? selectedButtonClasses : buttonClasses
        }
      >
        English
      </button>
      <button
        onClick={() => changeLanguage("sr")}
        className={
          i18n.language === "sr" ? selectedButtonClasses : buttonClasses
        }
      >
        Srpski
      </button>
    </div>
  );
};

export default LanguageSwitcher;
