import http from "./common";
import User from "../models/api/user.api";

class UserService {
  save(user: User) {
    return http.put<User>(`/users/${user.id}`, user);
  }
}

export default new UserService();
