import { useQuery } from "@tanstack/react-query";
import { LoaderFunctionArgs, NavLink, useNavigate, useParams } from "react-router-dom";
import { DEFAULT_PAGE_SIZE, queryClient } from "../../api/common";
import adService from "../../api/ad.service";
import Paged from "../../components/pagination/Paged";
import { useTranslation } from "react-i18next";
import AdStatusBadge from "../../components/AdStatusBadge";

const AdsList = () => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, null, "ad.list");

  const params = useParams();
  const pageNumber = params.pageNumber ? Number(params.pageNumber) : 1;
  const navigate = useNavigate();

  const { data, isPending, isLoading, isRefetching } = useQuery({
    queryKey: ["ads", pageNumber - 1, DEFAULT_PAGE_SIZE],
    queryFn: () => adService.list(pageNumber - 1, DEFAULT_PAGE_SIZE),
  });

  const openDetail = (adId: number) => {
    navigate(`/ads/${adId}`);
  };

  const openSetup = () => {
    navigate(`/ads/setup`);
  };

  return (
    <>
      <div className="mt-4 sm:flex sm:w-auto justify-end sm:px-6 md:px-8 lg:px-8">
        <button
          type="button"
          onClick={openSetup}
          className="block rounded-md bg-blue-600 px-3 py-1.5 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        >
          {t("create")}
        </button>
      </div>
      <Paged
        currentPage={pageNumber}
        pagesCount={data?.data.totalPages || 0}
        itemName="ad"
        totalItems={data?.data.totalElements || 0}
        className={`sm:px-6 md:px-8 lg:px-8 ${isPending || isRefetching ? "animate-strong-pulse" : ""}`}
      >
        <ul role="list" className={`divide-y divide-gray-100 ${isPending || isRefetching ? "animate-strong-pulse" : ""}`}>
          {data?.data.content.map((ad) => (
            <li
              key={ad.id}
              onClick={() => openDetail(ad.id || 0)}
              className="flex items-center justify-between gap-x-6 py-5 cursor-pointer sm:px-6 md:px-8 lg:px-8"
            >
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {ad.name}
                  </p>
                  <AdStatusBadge ad={ad} />
                </div>
                {ad.campaign && <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <NavLink to={`/campaigns/${ad.campaign.id}`} className="whitespace-nowrap">
                    {ad.campaign.name}
                  </NavLink>
                </div>}
              </div>
              <div className="min-w-0">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  {t("time_range", {"start": ad?.startDate, "end":ad?.endDate})}
                </p>
              </div>
              <div className="flex flex-none items-center gap-x-4"></div>
            </li>
          ))}
        </ul>
      </Paged>
    </>
  );
};

export default AdsList;

export function loader({ params }: LoaderFunctionArgs) {
  const pageNumber = params.pageNumber ? Number(params.pageNumber) - 1 : 0;
  return queryClient.fetchQuery({
    queryKey: ["ads", pageNumber, DEFAULT_PAGE_SIZE],
    queryFn: () => adService.list(pageNumber, DEFAULT_PAGE_SIZE),
  });
}
