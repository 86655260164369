import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import adService from "../../../api/ad.service";
import { DEFAULT_PAGE_SIZE, queryClient } from "../../../api/common";
import AdStatusBadge from "../../../components/AdStatusBadge";
import Paged from "../../../components/pagination/Paged";
import Ad from "../../../models/api/ad.api";
import APIPaged from "../../../models/api/paged.api";
import { useTranslation } from "react-i18next";
import { CheckBadgeIcon, EyeIcon } from "@heroicons/react/24/outline";
import AdTarget from "../../../models/api/ad-target.api";

const PagedPlacementDetailAdList: React.FC<{
  ads?: APIPaged<Ad>;
  placementId: string | undefined;
}> = ({ ads, placementId }) => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, null, "placement.detail.ad_list");

  const navigate = useNavigate();

  const approveMutation = useMutation({
    mutationFn: (vars: { ad: Ad; target: AdTarget }) =>
      adService.approve(vars.ad, vars.target),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["ads", "placementId", placementId],
      });
    },
  });

  const approveAd = (ad: Ad, target?: AdTarget) => {
    if (target !== undefined) {
      approveMutation.mutate({ ad, target });
    }
  };

  const viewAd = (ad: Ad) => navigate(`/ads/${ad.id}`);

  return (
    <>
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-900/10">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    {t("table.name")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("table.schedule")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("table.status")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900"
                  ></th>
                  <th
                    scope="col"
                    className="relative px-4 py-4 sm:px-6 md:px-8 lg:px-8 sm:pr-0"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {ads?.content?.map((ad) => {
                  const target = ad.targets?.find(
                    (t) => t.placement?.id == placementId
                  );
                  return (
                    <tr key={ad.id}>
                      <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm sm:pl-0">
                        <div className="flex items-center">
                          <div>
                            <div className="font-medium text-gray-900">
                              {ad.name}
                            </div>
                            <div className="mt-1 text-gray-500">
                              {ad.campaign?.name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm text-gray-500">
                        <div className="text-gray-900">
                          {t("table.date", {
                            date: new Date(ad.startDate),
                          })}{" "}
                          -{" "}
                          {t("table.date", {
                            date: new Date(ad.endDate),
                          })}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm text-gray-500">
                        <AdStatusBadge ad={ad} target={target} />
                      </td>
                      <td className="relative whitespace-nowrap">
                        {!target?.approved && (
                          <CheckBadgeIcon
                            className="inline w-6 h-6 cursor-pointer text-gray-600 hover:text-blue-600 flex-shrink-0"
                            title={t("actions.approve")}
                            onClick={() => approveAd(ad, target)}
                          />
                        )}
                        <EyeIcon
                          className="ml-2 inline w-6 h-6 cursor-pointer text-gray-600 hover:text-blue-600 flex-shrink-0"
                          title={t("actions.view")}
                          onClick={() => viewAd(ad)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

const PlacementDetailAdList = () => {
  const { placementId } = useParams();
  const [adPageCount, setAdPageCount] = useState(-1);

  const [adsPageNumber, setAdsPageNumber] = useState(1);

  const adsQuery = useQuery({
    queryKey: [
      "ads",
      "placementId",
      placementId,
    ],
    queryFn: () =>
      adService.list(adsPageNumber - 1, DEFAULT_PAGE_SIZE, Number(placementId)),
  });

  const ads = adsQuery?.data?.data;

  if (!adsQuery?.isFetching && adPageCount == -1) {
    setAdPageCount(ads?.totalPages || 0);
  }

  return (
    <>
      <Paged
        currentPage={adsPageNumber}
        pagesCount={adPageCount}
        onPageChange={(page: number) => setAdsPageNumber(page)}
        className="sm:px-6 md:px-8 lg:px-8"
        loading={adsQuery?.isFetching}
        itemName="ad"
        createUrl="/ad/new"
        totalItems={ads?.totalElements || 0}
      >
        <PagedPlacementDetailAdList
          ads={ads}
          placementId={placementId}
        />
      </Paged>
    </>
  );
};

export default PlacementDetailAdList;
