import { LoaderFunctionArgs, useParams } from "react-router-dom";
import StatsCard from "../../../components/stats/StatsCard";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../../../api/common";
import adService from "../../../api/ad.service";
import AdDetailTargetingList from "./AdDetailTargetingList";
import AdDetailCreativePreview from "./AdDetailCreativePreview";
import AdStatusBadge from "../../../components/AdStatusBadge";
import { useTopbarSelection } from "../../../store/topbar-selection-context";
import adStatsService from "../../../api/ad-stats.service";
import { AdStatsRequestBuilder } from "../../../models/api/ad-stats-request.api";
import { useTranslation } from "react-i18next";

const AdDetail = () => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, null, "ad.detail");

  const statsTimeSpan = useTopbarSelection()?.selectedTimeSpan;

  const { adId } = useParams();

  const { data: adData } = useQuery({
    queryKey: ["ads", adId],
    queryFn: () => adService.single(Number(adId)),
  });

  const ad = adData?.data;

  const { data: adImageData } = useQuery({
    queryKey: ["ads", adId, "image"],
    queryFn: () => adService.image(Number(adId)),
    enabled: !!ad?.id,
  });

  const { data: stats } = useQuery({
    queryKey: ["ads-stats", adId, statsTimeSpan],
    queryFn: async () => {
      if (adId && statsTimeSpan) {
        const stats = await adStatsService.get(
          new AdStatsRequestBuilder()
            .withAd(Number(adId))
            .withTimeSpan(statsTimeSpan.getSpan())
            .build()
        );

        return adStatsService.sumAll(stats.data).stats[0];
      }
    },
  });

  const { data: prevStats } = useQuery({
    queryKey: ["ads-prev-stats", adId, statsTimeSpan],
    queryFn: async () => {
      if (adId && statsTimeSpan) {
        const stats = await adStatsService.get(
          new AdStatsRequestBuilder()
            .withAd(Number(adId))
            .withTimeSpan(statsTimeSpan.getPreviousSpan())
            .build()
        );

        return adStatsService.sumAll(stats.data).stats[0];
      }
    },
  });

  return (
    <>
      <main>
        <header>
          <div className="flex flex-col items-start gap-x-8 gap-y-4 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
            <div>
              <div className="flex items-center gap-x-3">
                <h1 className="flex gap-x-3 text-base leading-7">
                  <span className="font-semibold text-gray-900">
                    {ad?.name}
                  </span>
                  <span className="text-gray-600">/</span>
                  <span className="font-semibold text-gray-900">
                    {t("time_range", {"start": ad?.startDate, "end":ad?.endDate})}
                  </span>
                </h1>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap"></p>
              </div>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="truncate">{ad?.url}</p>
              </div>
            </div>
            {ad && <AdStatusBadge ad={ad} />}
          </div>
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              <StatsCard
                index={0}
                title={t("stats.impressions")}
                value={stats?.impressions || 0}
                prevValue={prevStats?.impressions || 0}
              />
              <StatsCard
                index={1}
                title={t("stats.clicks")}
                value={stats?.clicks || 0}
                prevValue={prevStats?.clicks || 0}
                tooltip="Test"
              />
              {/* TODO */}
              <StatsCard index={2} title={t("stats.spend")} value={300} />
              <StatsCard index={3} title={t("stats.targets")} value={3} />
            </dl>
          </div>
        </header>
        <div className="px-4 pt-4 sm:px-6 sm:pt-6 lg:px-8">
          <h1 className="text-base font-semibold leading-7 text-gray-900">
            {t("creative.title")}
          </h1>
          <div className="p-4">
            {adImageData?.data.imageData && (
              <AdDetailCreativePreview
                image={`data:image/png;base64,${adImageData?.data.imageData}`}
              />
            )}
          </div>
        </div>
        <div className="px-4 pt-4 sm:px-6 sm:pt-6 lg:px-8">
          <h1 className="text-base font-semibold leading-7 text-gray-900">
          {t("targeting.title")}
          </h1>
        </div>
        <AdDetailTargetingList targets={ad?.targets} />
      </main>
    </>
  );
};

export default AdDetail;

export function loader({ params }: LoaderFunctionArgs) {
  return queryClient.fetchQuery({
    queryKey: ["ads", params.adId],
    queryFn: () => adService.single(Number(params.adId)),
  });
}
