import AdStatsRequest, {
  AdStatsDimension,
} from "../models/api/ad-stats-request.api";
import AdStatsResponse from "../models/api/ad-stats-response.api";
import AdStats from "../models/api/ad-stats.api";
import http from "./common";

class AdStatsService {
  public get(request: AdStatsRequest) {
    return http.post<AdStatsResponse>("/ads/stats", request);
  }

  public sumTotal(response: AdStatsResponse): AdStatsResponse {
    return this.sumByKeys(response, () => "0");
  }

  public sumAll(response: AdStatsResponse): AdStatsResponse {
    return this.sumByKeys(response, (stat) =>
      [
        stat.ad?.id,
        stat.campaign?.id,
        stat.placement?.id,
        stat.website?.id,
        stat.contentCategory?.id,
      ].join("|")
    );
  }

  public sum(
    response: AdStatsResponse,
    dimensions: AdStatsDimension[]
  ): AdStatsResponse {
    return this.sumByKeys(response, (stat) => {
      const keys = [];

      if (dimensions.find((d) => d === AdStatsDimension.DATA_TIME)) {
        keys.push(stat.dataTime);
      }

      if (dimensions.find((d) => d === AdStatsDimension.PLACEMENT)) {
        keys.push(stat.placement?.id);
      }

      return keys.join("|");
    });
  }

  private sumByKeys(
    response: AdStatsResponse,
    keyProvider: (stat: AdStats) => string
  ): AdStatsResponse {
    const groupedStats: { [key: string]: AdStats } = {};

    response.stats.forEach((stat) => {
      const key = keyProvider(stat);

      if (!groupedStats[key]) {
        // clone to avoid modifying the original
        groupedStats[key] = { ...stat, impressions: 0, clicks: 0, requests: 0 };
      }

      groupedStats[key].impressions += stat.impressions;
      groupedStats[key].clicks += stat.clicks;
      groupedStats[key].requests += stat.requests;
    });

    return {
      stats: Object.values(groupedStats),
    };
  }
}

export default new AdStatsService();
