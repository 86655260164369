import HasUnreadNotifications from "../models/api/has-unread-notifications.api";
import Notification from "../models/api/notification.api";
import Paged from "../models/api/paged.api";
import http from "./common";

class NotificationService {
    list(page: number = 0, size: number = 20) {
        return http.get<Paged<Notification>>(`/notifications?page=${page}&size=${size}&sort=id,desc`);
    }

    hasUnread() {
        return http.get<HasUnreadNotifications>(`/notifications/has-unread`);
    }

    markAsRead(id: number) {
        return http.put<Notification>(`/notifications/${id}`, {});
    }
}

export default new NotificationService();