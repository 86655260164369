export enum NotificationType {
  APPROVE_AD = "APPROVE_AD",
  AD_APPROVED = "AD_APPROVED",
}
export default interface Notification {
  id: number;
  placeholderValues: string[];
  type: NotificationType;
  read: boolean;
}
