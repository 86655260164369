import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import Website from "../models/api/website.api";
import TimeSpan from "../models/ui/time-span";

interface TopbarSelectionContextType {
  selectedWebsite: Website | null;
  setSelectedWebsite: (website: Website | null) => void;
  selectedTimeSpan: TimeSpan | null;
  setSelectedTimeSpan: (website: TimeSpan | null) => void;
}

const TopbarSelectionContext = createContext<TopbarSelectionContextType>({
  selectedWebsite: null,
  setSelectedWebsite: () => {},
  selectedTimeSpan: null,
  setSelectedTimeSpan: () => {},
});

export const TopbarSelectionContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [selectedWebsite, setSelectedWebsite] = useState<Website | null>(null);
  const [selectedTimeSpan, setSelectedTimeSpan] = useState<TimeSpan | null>(null);

  useEffect(() => {
    const storedWebsite = JSON.parse(
      localStorage.getItem("selectedWebsite") || "{}"
    ) as Website;
    storedWebsite && setSelectedWebsite(storedWebsite);

    const storedSpan = localStorage.getItem("selectedTimeSpan");
    setSelectedTimeSpan(storedSpan ? TimeSpan.fromString(storedSpan) : TimeSpan.LAST_24_HOURS);
  }, []);

  useEffect(() => {
    selectedWebsite &&
      localStorage.setItem("selectedWebsite", JSON.stringify(selectedWebsite));
  }, [selectedWebsite]);

  useEffect(() => {
    selectedTimeSpan &&
      localStorage.setItem("selectedTimeSpan", selectedTimeSpan.value);
  }, [selectedTimeSpan]);

  return (
    <TopbarSelectionContext.Provider
      value={{
        selectedWebsite,
        setSelectedWebsite,
        selectedTimeSpan,
        setSelectedTimeSpan,
      }}
    >
      {children}
    </TopbarSelectionContext.Provider>
  );
};

// Custom hook to use the TopbarSelectionContext
export const useTopbarSelection = (): TopbarSelectionContextType => {
  const context = useContext(TopbarSelectionContext);
  if (!context) {
    throw new Error(
      "useTopbarSelection must be used within a TopbarSelectionContextProvider"
    );
  }
  return context;
};
