import { NavLink, useNavigate, useParams } from "react-router-dom";
import CardPaymentForm from "./payment/CardPaymentForm";
import SerbianPaymentSlip from "./payment/SerbianPaymentSlip";
import { useMutation, useQuery } from "@tanstack/react-query";
import adService from "../../../api/ad.service";
import AdTargetingLabel from "../../../components/AdTargetingLabel";
import Ad from "../../../models/api/ad.api";
import { CornerBottomLeftIcon } from "@radix-ui/react-icons";
import { differenceInDays, format } from "date-fns";
import AdTarget from "../../../models/api/ad-target.api";
import { useTranslation } from "react-i18next";

const AdSetupPaymentStep: React.FC = () => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, null, "ad.setup.steps.payment");

  const { adId } = useParams();

  // TODO error handling
  const { data: ad } = useQuery({
    queryKey: ["ads", adId],
    queryFn: async () => adService.single(Number(adId)),
    refetchOnMount: true,
  });

  const daysBetween = ad?.data ? differenceInDays(ad?.data?.endDate, ad?.data?.startDate) : 0
  const totalPrice =
    ad?.data?.targets?.reduce(
      (acc, { placement: pl, priceType: pt }) =>
        acc + (pl?.price?.find((p) => p.type === pt)?.value || 0),
      0
    ) || 0;

  return (
    <div className="flex md:flex-row flex-col">
      <div className="flex-1 min-h-full">
        <PaymentInfo amount={i18n.t("defaults.amount.currency", { amount: totalPrice * daysBetween})} />
      </div>
      <div className="flex-1 ml-2 flex flex-col p-4 md:bg-gray-50 bg-white text-sm rounded-lg h-fit">
        <TargetingSummary ad={ad?.data} />
      </div>
    </div>
  );
};

const TargetingSummary: React.FC<{ ad?: Ad }> = ({ ad }) => {
  const { i18n } = useTranslation();
  const t = i18n.getFixedT(null, null, "ad.setup.steps.payment.targets");

  const navigate = useNavigate();

  const payMutation = useMutation({
    mutationFn: async (vars: { ad: Ad }) => {
      vars.ad.targets?.forEach((t) => adService.pay(vars.ad, t));
      return ad;
    },
    onSuccess: () => navigate("/ads"),
  });

  const payAd = (ad?: Ad) => {
    if (ad) {
      payMutation.mutate({ ad });
    }
  };

  const daysBetween = ad ? differenceInDays(ad?.endDate, ad?.startDate) : 0;

  const totalPrice =
    ad?.targets?.reduce(
      (acc, { placement: pl, priceType: pt }) =>
        acc + (pl?.price?.find((p) => p.type === pt)?.value || 0),
      0
    ) || 0;

  return (
    <div className="w-full flex flex-col items-end">
      <div className="w-full">
        <h3 className="text-lg font-semibold w-full border-b mb-2">{t("title")}</h3>
        {ad?.targets?.map((t) => (
          <div className="flex flex-row">
            <CornerBottomLeftIcon className="h-4 w-4 text-gray-400" />
            <AdTargetingLabel
              target={t}
              key={t.placement?.id}
              className="mb-2"
            />
          </div>
        ))}
      </div>
      <dl className="mt-10 space-y-2 text-sm font-medium text-gray-500 md:w-1/2">
        <div className="flex justify-between">
          <dt>{t("total_per_day")}</dt>
          <dd className="text-gray-900">{i18n.t("defaults.amount.currency", { amount: totalPrice })} RSD</dd>
        </div>
        <div className="flex justify-between">
          <dt>{t("days")}</dt>
          <dd className="text-gray-900">{daysBetween}</dd>
        </div>
        <div className="flex justify-between border-t border-gray-200 pt-4 text-gray-900">
          <dt className="text-base">{t("total")}</dt>
          <dd className="text-base">{i18n.t("defaults.amount.currency", { amount: totalPrice * daysBetween})} RSD</dd>
        </div>
      </dl>
      <div
        onClick={() => payAd(ad)}
        className="min-w-24 mt-4 inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 disabled:bg-blue-300 disabled:text-gray-100 disabled:cursor-not-allowed transition-all duration-300"
      >
        {i18n.t("ad.setup.steps.payment.finish")}
      </div>
    </div>
  );
};

const PaymentInfo: React.FC<{amount: string}> = ({ amount }) => {
  const cardEnabled = false;
  return (
    <>
      {cardEnabled && (
        <>
          <CardPaymentForm />
          <div className="relative mt-8">
            <div
              aria-hidden="true"
              className="absolute inset-0 flex items-center"
            >
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-4 text-sm font-medium text-gray-500">
                or
              </span>
            </div>
          </div>
        </>
      )}
      <div className="p-6">
        <SerbianPaymentSlip
          payerName="Marko Marković"
          payerAddress="Ulica 123"
          payerCity="Beograd"
          recipientName="Kompanija d.o.o."
          recipientAccount="160-0000000000000-12"
          paymentPurpose="Uplata za usluge"
          paymentAmount={amount}
          referenceNumber="97-123456789"
          model="97"
          recipientCity="Novi Sad"
        />
      </div>
    </>
  );
};

export default AdSetupPaymentStep;
