import UIPropsBase from "../../models/ui/props";

const Card: React.FC<UIPropsBase> = ({ children, className }) => {
  return (
    <div className={`${className} overflow-hidden bg-white sm:rounded-lg border`}>
      <div className="">{children}</div>
    </div>
  );
};

export default Card;
