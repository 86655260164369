import { useNavigate } from "react-router-dom";
import Notification, { NotificationType } from "../models/api/notification.api";
import { useTranslation } from "react-i18next";
import notificationService from "../api/notification.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../api/common";
import { LoadingSpinner } from "./LoadingSpinner";

function typeToIcon(type: NotificationType) {
  switch (type) {
    case NotificationType.APPROVE_AD: {
      return "/img/placement/in_list.svg";
    }
    default: {
      return "";
    }
  }
}

const NotificationsDrawerContent: React.FC<{ shouldFetch: boolean }> = ({
  shouldFetch,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const markAsReadMutation = useMutation({
    mutationFn: (vars: { id: number }) =>
      notificationService.markAsRead(vars.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
  });

  const { data, isPending } = useQuery({
    queryKey: ["notifications"],
    queryFn: () => notificationService.list(),
    enabled: shouldFetch,
    staleTime: 1000 * 5, // 5 seconds
  });

  let notifications: Notification[] = [];

  if (!isPending && data) {
    notifications = data?.data.content;
  }

  const openNotification = (notification: Notification) => {
    switch (notification.type) {
      case NotificationType.APPROVE_AD: {
        markAsReadMutation.mutate({ id: notification.id });
        navigate(`/placements/${notification.placeholderValues[4]}`);
        break;
      }
      default: {
        // do nothing
      }
    }
  };

  return (
    <>
      {isPending && <LoadingSpinner className="w-16 h-16" />}
      {!isPending && (
        <ul role="list" className="divide-y divide-gray-100">
          {notifications.map((notification) => (
            <li
              onClick={() => openNotification(notification)}
              key={notification.id}
              className={`${!notification.read && "bg-blue-50"} flex items-center justify-between space-y-2 p-4 cursor-pointer`}
            >
              <div className="flex flex-auto min-w-0">
                <img
                  alt=""
                  src={typeToIcon(notification.type)}
                  className="h-12 w-12 flex-none mr-2"
                />
                <div className="min-w-0 flex-auto items-center">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    {t(
                      "notification." +
                        notification.type.toLowerCase() +
                        ".title",
                      { ...notification.placeholderValues }
                    )}
                  </p>
                  <p className="whitespace-nowrap truncate leading-5 text-gray-500 text-xs">
                    {t(
                      "notification." +
                        notification.type.toLowerCase() +
                        ".body",
                      { ...notification.placeholderValues }
                    )}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default NotificationsDrawerContent;
