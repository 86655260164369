import ProgressSteps, { Step } from "../../../components/ProgressSteps";
import { Outlet, useMatch, useNavigation } from "react-router-dom";
import LoadingIndicator from "../../../components/LoadingIndicator";

const AdSetup: React.FC = () => {
  const navigation = useNavigation();

  const matchDetails = useMatch("/ads/setup");
  const matchTargeting = useMatch("/ads/setup/:adId/targeting");
  const matchPayment = useMatch("/ads/setup/:adId/payment");

  const detailsStepStatus = matchDetails ? "current" : "complete";
  const targetingStepStatus = matchTargeting ? "current" : matchPayment ? "complete" : "upcoming";
  const paymentStepStatus = matchPayment ? "current" : "upcoming";

  const steps: Step[] = [
    { id: "01", name: "Ad details", href: "#", status: detailsStepStatus },
    { id: "02", name: "Targeting", href: "#", status: targetingStepStatus },
    { id: "03", name: "Payment", href: "#", status: paymentStepStatus },
  ];

  return (
    <div className="sm:px-6 md:px-8 lg:px-8">
      <ProgressSteps steps={steps} />
      <div className="flex-grow p-4 border border-t-0 rounded-b-md">
        {navigation.state === "loading" && <LoadingIndicator />}
        {navigation.state !== "loading" && <Outlet />}
      </div>
    </div>
  );
};

export default AdSetup;
