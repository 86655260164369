import React from "react";
import AdTarget from "../../../models/api/ad-target.api";
import WebsiteIcon from "../../../components/WebsiteIcon";
import { cn } from "../../../lib/shadcn/utils";
import { useTranslation } from "react-i18next";

const AdDetailTargetingList: React.FC<{
  targets?: AdTarget[];
}> = ({ targets }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flow-root">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-900/10">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    {t("ad.detail.targeting.website")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("ad.detail.targeting.placement")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("ad.detail.targeting.status")}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("ad.detail.targeting.type")}
                  </th>
                  <th
                    scope="col"
                    className="relative px-4 py-4 sm:px-6 md:px-8 lg:px-8 sm:pr-0"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {targets?.map((target) => (
                  <tr key={target.id}>
                    <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm sm:pl-0">
                      <div className="flex items-center">
                        <WebsiteIcon
                          className="mr-4 invisible md:visible"
                          website={target.placement?.website.url || ""}
                        />
                        <div>
                          <div className="font-medium text-gray-900">
                            {target.placement?.website.name}
                          </div>
                          <div className="mt-1 text-gray-500">
                            {target.placement?.website.url}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm text-gray-500">
                      <div className="flex items-center">
                        <img
                          className="mr-4 invisible md:visible"
                          src={`/img/placement/${target.placement?.category.toLowerCase()}.svg`}
                          alt={t(`placement.category.${target.placement?.category.toLowerCase()}`)}
                        />
                        <div>
                          <div className="text-gray-900">
                            {target.placement?.name}
                          </div>
                          <div className="mt-1 text-gray-500">
                            {t(`placement.category.${target.placement?.category.toLowerCase()}`)}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm text-gray-500">
                      <span
                        className={cn(
                          target.paid
                            ? "text-green-700 ring-green-600/20 bg-green-50"
                            : "text-red-700 ring-red-600/20 bg-red-50",
                          "mr-2 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium  ring-1 ring-inset"
                        )}
                      >
                        {t(`ad.status.badge.${target.paid ? "paid" : "unpaid"}`)}
                      </span>
                      <span
                        className={cn(
                          target.approved
                            ? "text-green-700 ring-green-600/20 bg-green-50"
                            : "text-red-700 ring-red-600/20 bg-red-50",
                          "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium  ring-1 ring-inset"
                        )}
                      >
                        {t(`ad.status.badge.${target.approved ? "approved" : "unapproved"}`)}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm text-gray-900">
                      {t(`ad.target.price_type.${target.priceType.toLowerCase()}`)}
                    </td>
                    <td className="relative whitespace-nowrap px-4 py-4 sm:px-6 md:px-8 lg:px-8 text-sm font-medium sm:pr-0">
                      <a href="#" className="text-blue-600 hover:text-blue-900">
                        Edit<span className="sr-only">, {"UNK2"}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdDetailTargetingList;
