import {
  CommandLineIcon,
  CodeBracketSquareIcon,
  Cog8ToothIcon,
  ComputerDesktopIcon,
  ArrowLeftStartOnRectangleIcon,
} from "@heroicons/react/24/outline";
import UIPropsBase from "../../models/ui/props";
import { useState } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  Sidebar,
  SidebarBody,
  SidebarLink,
} from "../../components/sidebar/Sidebar";
import { cn } from "../../lib/shadcn/utils";
import Topbar from "../../components/topbar/Topbar";
import { ScrollArea } from "../../components/shadcn/scroll-area";
import { TopbarSelectionContextProvider } from "../../store/topbar-selection-context";
import WebsiteSelector from "../../components/WebsiteSelector";
import TimeSpanSelector from "../../components/TimeSpanSelector";
import NotificationsDrawerContent from "../../components/NotificationsDrawerContent";
import Drawer from "../../components/drawer/Drawer";

const ICON_CLASSES =
  "text-gray-400 group-hover/sidebar:text-blue-600 h-5 w-5 flex-shrink-0";

const links = [
  {
    label: "dashboard",
    href: "/",
    icon: <CommandLineIcon className={ICON_CLASSES} />,
  },
  {
    label: "placements",
    href: "/placements",
    icon: <CodeBracketSquareIcon className={ICON_CLASSES} />,
  },
  {
    label: "ads",
    href: "/ads",
    icon: <ComputerDesktopIcon className={ICON_CLASSES} />,
  },
  {
    label: "settings",
    href: "/settings",
    icon: <Cog8ToothIcon className={ICON_CLASSES} />,
  },
];

const RootLayout: React.FC<UIPropsBase> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const openNotificationsDrawer = () => setNotificationsOpen(true);
  const closeNotificationsDrawer = () => setNotificationsOpen(false);

  const navigation = useNavigation();

  return (
    <>
      <TopbarSelectionContextProvider>
        {/* layout wrapper */}
        <div className="flex flex-col h-full">
          <Topbar onNotificationsClicked={openNotificationsDrawer} />

          <div className="flex flex-row flex-grow">
            {/* menu wrapper */}
            <div
              className={cn(
                "flex flex-col md:flex-row mx-auto border-neutral-200 overflow-hidden",
                "h-full"
              )}
            >
              <Sidebar open={sidebarOpen} setOpen={setSidebarOpen}>
                <SidebarBody className="justify-between gap-5 md:gap-10">
                  <div className="flex flex-col md:hidden border-b pb-6 max-h-lvh">
                    <span className="text-xs text-gray-400">Date Range</span>
                    <TimeSpanSelector />
                    <span className="text-xs text-gray-400 mt-2">
                      Selected website
                    </span>
                    <WebsiteSelector />
                  </div>
                  <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <div className="flex flex-col gap-2">
                      {links.map((link, idx) => (
                        <SidebarLink key={idx} link={link} />
                      ))}
                    </div>
                  </div>
                  <div>
                    <SidebarLink
                      link={{
                        label: "logout",
                        href: "#",
                        icon: (
                          <ArrowLeftStartOnRectangleIcon
                            className={ICON_CLASSES}
                          />
                        ),
                      }}
                    />
                  </div>
                </SidebarBody>
              </Sidebar>
            </div>

            {/* content wrapper */}
            <div className="flex-grow h-full pt-8">
              <ScrollArea className="h-full w-full rounded-md">
                {navigation.state === "loading" && <LoadingIndicator />}
                {navigation.state !== "loading" && <Outlet />}
              </ScrollArea>
            </div>
          </div>
        </div>
      </TopbarSelectionContextProvider>

      <Drawer
        open={notificationsOpen}
        onClose={closeNotificationsDrawer}
        title="Notifications"
      >
        <NotificationsDrawerContent shouldFetch={notificationsOpen} />
      </Drawer>
    </>
  );
};

export default RootLayout;
