import AdStatsTimeSpan from "../ui/ad-stats-time-span";
import AdStatsFilter from "./ad-stats-filter.api";

export enum AdStatsDimension {
    DATA_TIME = "DATA_TIME",
	PLACEMENT = 'PLACEMENT',
	WEBSITE = 'WEBSITE',
	WEBSITE_PAGE = 'WEBSITE_PAGE',
    AD = 'AD',
    CAMPAIGN = 'CAMPAIGN',
}

export enum AdStatsGranularity {
	HOURLY = 'HOURLY',
	DAILY = 'DAILY',
	MONTHLY = 'MONTHLY',
}

export default interface AdStatsRequest {
    filters: AdStatsFilter[];
    dimensions: AdStatsDimension[];
    granularity: AdStatsGranularity;
    startDate: Date;
    endDate: Date;
}

export class AdStatsRequestBuilder {
    private filters: AdStatsFilter[] = [];
    private dimensions: AdStatsDimension[] = [];
    private granularity: AdStatsGranularity = AdStatsGranularity.DAILY; // Default value
    private startDate?: Date;
    private endDate?: Date;

    constructor() {}

    withFilter(dimension: AdStatsDimension, value: number): AdStatsRequestBuilder {
        this.filters.push({ dimension, value });
        return this;
    }

    withDimension(dimension: AdStatsDimension): AdStatsRequestBuilder {
        this.dimensions.push(dimension);
        return this;
    }

    withGranularity(granularity: AdStatsGranularity): AdStatsRequestBuilder {
        this.granularity = granularity;
        return this;
    }

    withTimeSpan(timeSpan: AdStatsTimeSpan): AdStatsRequestBuilder {
        this.withDateRange(timeSpan.startDate, timeSpan.endDate);
        this.withGranularity(timeSpan.granularity);
        return this;
    }

    withDateRange(startDate: Date, endDate: Date): AdStatsRequestBuilder {
        this.startDate = startDate;
        this.endDate = endDate;
        return this;
    }

    withAd(value: number): AdStatsRequestBuilder {
        return this.withFilterAndDimension(AdStatsDimension.AD, value);
    }

    withCampaign(value: number): AdStatsRequestBuilder {
        return this.withFilterAndDimension(AdStatsDimension.CAMPAIGN, value);
    }

    withPlacement(value: number): AdStatsRequestBuilder {
        return this.withFilterAndDimension(AdStatsDimension.PLACEMENT, value);
    }

    withWebsite(value: number): AdStatsRequestBuilder {
        return this.withFilterAndDimension(AdStatsDimension.WEBSITE, value);
    }

    withContentCategory(value: number): AdStatsRequestBuilder {
        return this.withFilterAndDimension(AdStatsDimension.WEBSITE_PAGE, value); // Assuming CONTENT_CATEGORY relates to WEBSITE_PAGE
    }

    private withFilterAndDimension(dimension: AdStatsDimension, value: number): AdStatsRequestBuilder {
        this.withFilter(dimension, value);
        this.withDimension(dimension);
        return this;
    }

    build(): AdStatsRequest {
        if (!this.startDate || !this.endDate) {
            throw new Error('Start and end dates must be set.');
        }
        return {
            filters: this.filters,
            dimensions: this.dimensions,
            granularity: this.granularity,
            startDate: this.startDate,
            endDate: this.endDate
        };
    }
}
